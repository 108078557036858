@import "Settings"; //Måste vara först, innehåller alla variabler
@import "Reset";
@import "Base";
@import "Agreement";
@import "Home";
@import "Login";
@import "Sign";
@import "MyAccount";
@import "Dropdown";
@import "Templates";

.button {
  color: white;
  background-color: black;
  cursor: pointer;
  border-radius: 3px;
  border: none;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.4);
  padding: 3px 10px;
  font-size: 1.6rem;

  &--add-new {
    border: 1px solid $color-3b;
    background-color: $color-3;
    color: $color-3b;
    margin-bottom: 12px;
    margin-top: 9px;
  }

  &--large {
    margin-top: 2px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 9px;
    width: 96%;
    max-width: 700px;
    height: 43px;
    display: block;
  }

  &--create-agreement {
    height: 30px;
    margin: 0px;
    padding: 1px 7px;
    box-sizing: border-box;
    border-radius: 0px;
  }

  &--add-new-paragraph {
    margin-bottom: 10px;
    margin-top: 9px;
  }

  &--warning {
    color: black;
    background-color: red;
  }

  &--bottom-right {
    position: fixed;
    bottom: 5%;
    right: 5%;
  }

  &--circle {
    border-radius: 50%;
    width: 62px;
    height: 62px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $color-1;
    border: none;
  }

  &--delete-account {
    position: absolute;
    bottom: 12px;
    right: 7px;
  }

  &--sign {
    margin: 3px 5px 13px 5px;
  }

  &--log-out {
    margin-top: 15px;
    width: 12rem;
  }

  &--save {
    font-size: 1.05rem;
  }
}

.back {
  background-color: $color-1;
  position: relative;
  float: left;
  padding: 0px 5px 0px 11px;
  height: 100%;
  border: none;
}

.logo-container {
  margin-top: 8px;
  text-align: center;
}

#start-screen-login .logo-container {
  margin-top: 50px;
}