.start-screen {
  padding: 12px;
  overflow: auto;
  box-sizing: border-box;
}

.screen-holder {
  width: 100%;
  max-width: 1024px;
  position: relative;
  background-color: $background-color;
}

.agreement-list {
  margin-top: 20px;
}

.agreement-list__item {
  background-color: white;
  text-align: left;
  width: 100%;
  height: 40px;
  position: relative;
  display: flex;
  align-items: center;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.4);
  border: none;
  margin-bottom: 4px;
  padding-left: 8px;
  font-size: 1.6rem;
}

.create-agreement {
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 3px;
  overflow: hidden;
  margin-top: 2px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.4);
}

.logo-container__heading {
  font-family: 'Sacramento', cursive;
  font-weight: 300;
  margin-bottom: 5px;
  font-size: 4.5rem;
}

.agreement-list__heading {
  margin-top: 20px;
  margin-bottom: 7px;
  font-size: 1.8rem;
  font-weight: 500;
}

.fa-handshake {
  margin-right: 7px;
  font-size: 22px;
}

.plus-sign {
  height: 29px;
  fill: $color-1b;
}

.show-feedback {
  border: 2px solid black;
  border-bottom: 0;
  padding: 7px 12px;
  font-size: 16px;
  text-align: center;
  background-color: $color-1;
}

.feedback-wrapper {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  flex-direction: column;
}