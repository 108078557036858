h1,
h2 {
  text-align: center;
}

h1 {
  font-size: 3rem;
}

h2 {
  font-size: 2.0rem;
}

p {
  font-size: 16px;
}

a {
  text-decoration: none;
}

button,
input {
  border-radius: 3px;
}

button {
  cursor: pointer;
}

input {
  font-size: 1.6rem;
  margin: 2px 2px;
}

textarea {
  border-radius: 3px;
  resize: none;
}

hr {
  margin: 0;
  background-color: $color-1;
}