@import "Settings";

.logo {
  margin-top: 70px;
  width: 100px;
  height: 100px;
  background-color: rgb(224, 221, 221);
  border-radius: 1px;
  padding: 4px 0px;
}

.logo--half {
  display: flex;
  align-items: center;
  width: 100%;
  height: 50%;
}

.toggle {
  width: 83%;
  height: 83%;
  display: flex;
  justify-content: flex-end;
  background-color: $color-1;
  margin: auto;
  border-radius: 1px;
}

.toggle--half {
  height: 100%;
  width: 50%;
  display: flex;
  align-items: center;
}

.square {
  border-radius: 1px;
  margin: auto;
  width: 70%;
  height: 70%;
  background-color: $color-1b;
}
