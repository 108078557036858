.login-screen {
  min-height: 100vh;
  background-color: #373433;
  color: black;
  display: flex;
  justify-content: center;
}

#start-screen-login {
  background-color: white;
  flex-direction: column;
  display: flex;
  align-items: center;
}

.both-like {
  margin-top: 50px;
  width: 130px;
}

.google-button {
  border-radius: 2px;
  width: 100%;
  cursor: pointer;
  margin-top: 75px;
  background: linear-gradient(180deg, #5d96f4, #4285f4);
  display: flex;
  box-shadow: 0 4px 16px rgba(67, 135, 244, 0.24);
  justify-content: center;
  height: 40px;
  min-width: 250px;
  position: relative;

  .google-logo {
    position: absolute;
    left: 0;
    margin: -3px;
  }

  &__content {
    font-size: 1.5rem;
    font-weight: 400;
    color: #fff;
    align-self: center;
  }
}

.terms {
  color: hsl(0, 0%, 40%);
  margin: 30px 0px;
  width: 100%;

  a {
    white-space: nowrap;
  }
}

.capitalize {
  text-transform: capitalize;
}