.my-account {
  padding: 12px;
  text-align: initial;
  display: flex;
  flex-direction: column;
}

.personal-data {
  margin-top: 1.5rem;
  display: flex;
}

.personal-data__input {
  width: 50%;
}
