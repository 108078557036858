.canvas-container {
  border: 1px solid lightgray;
}

.signature-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.signature {
  font-size: 16px;
  text-align: left;
  margin: 3px 5px 10px 4px;
}
