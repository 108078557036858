.dd-wrapper {
  height: $top-navbar-height;
  list-style-type: none;
}

.dd-header {
  height: 100%;
  display: list-item;
}

.dd-header-title {
  height: 100%;
  cursor: pointer;
  position: relative;
  padding: 0px 21px 0px 15px;
  background-color: $color-1;
  display: flex;
  align-items: center;
}

.dd-list {
  position: absolute;
  right: 0;
  list-style-type: none;
  text-align: left;
  font-size: 1.6rem;
  padding-left: 0px;
  width: 83%;
  background-color: white;
  box-shadow: -2px 3px 5px 1px rgba(0, 0, 0, 0.2);
  padding: 5px 0px;
}

.dd-list-item {
  background-color: white;
}

.delete-agreement {
  text-align: center;
  color: $color-delete;
  cursor: pointer;
}

.dd-list-item__header {
  padding: 9px 0px;
  margin-left: 15px;
}

.dd-list-item__body {
  background-color: #d3d3d39c;
  padding: 9px 0px 0px 9px;
}

.member {
  padding-bottom: 9px;
}

.name {
  margin-bottom: 3px;
}