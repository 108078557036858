@import "Settings";

.feedback {
    padding: 10px;
    border-top: 2px solid black;
    width: 100%;
    background-color: $color-2;
    height: 200px;
    display: none;
}

.feedback--show {
    display: block;
}

.feedback--text {
    margin-top: 10px;
}

.feedback--input {
    font-size: 1.6rem;
    margin: 12px 0;
    width: 95%;
}