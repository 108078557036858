.background {
  text-align: center;
  font-size: calc(10px + 2vmin);
}

.Agreement-link {
  color: #61dafb;
}

.agreement-box {
  background-color: $color-2;
  margin: 9px;
  border-radius: 7px;
  padding-right: 10px;
  padding-left: 10px;

  &--chapter {
    padding-top: 2px;
    padding-bottom: 4px;
  }

  &--search {
    padding-top: 2px;
    padding-bottom: 2px;
  }

  &--parties {
    padding-top: 4px;
    padding-bottom: 4px;
  }

  &--invite {
    padding-top: 8px;
  }
}

.chapter-header {
  text-align: left;
  display: flex;

  border-radius: 7px;
  background-color: $color-2;
  color: black;
  font-size: 1.6rem;
  align-items: end;

  strong {
    white-space: nowrap;
  }

  .parties-content {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .chapter-title__textarea {
    padding-top: 1px;
    width: 70%;
    resize: none;
    color: black;
    font-size: 1.6rem;
    outline: none;
    margin-bottom: 8px;
    min-height: 21px !important;
  }
}

.chapter-header--parties {
  padding: 9px 7px;
}

.chapter-header--chapter {
  padding: 8px 7px 0px 7px;
}

.chapter-index {
  height: 20px;
  display: flex;
  align-items: center;
}

.paragraph-content {
  margin-bottom: 5px;
  background-color: white;
  border-radius: 3px;
}

.paragraph-content__textarea {
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
  font-size: 1.6rem;
}

.editFalse {
  background-color: $color-2;
  border: 1px solid transparent;
  color: white;
}

.top-navbar {
  .editFalse {
    background-color: $color-1;
    color: $color-1b;
  }
}

.agreement-title {
  text-align: left;

  &__input {
    resize: none;
    color: black;
    font-size: 1.9rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }

  &__label {
    margin-left: 12px;
    width: 100%;
  }
}

.chapter-title {
  display: inline;
  width: 90%;
}

.paragraph {
  padding: 10px;
  margin-bottom: 3px;
  background-color: white;
  text-align: left;
  width: 100%;
  box-sizing: border-box;
  border: none;
  cursor: pointer;
  font-size: 1.6rem;
}

.paragraph--signed-or-locked {
  cursor: default;
}

.background .top-navbar {
  background-color: $color-1;
  height: $top-navbar-height;
  display: flex;
  align-items: center;
  position: relative;
}

.template-title {
  font-size: 20px;
  width: 70%;
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  margin-top: 0px;
}

.top-navbar__title {
  color: $color-1b;
  font-weight: 500;
  margin-left: 10px;
}

.fa-angellist {
  position: absolute;
  font-size: 3.1rem;
  left: 0;
  right: 0;
}

.remove-chapter {
  background-color: transparent;
  border: none;
  padding-top: 3px;
  padding-right: 3px;
  padding-bottom: 9px;
  padding-left: 9px;
  font-size: 14px;
  font-weight: 700;
  margin: 0;
}

.chapter-title textarea {
  vertical-align: middle;
}

.toggles {
  position: relative;
  display: inline-block;
  width: 100%;
  margin-top: 18px;
}

.toggleDiv {
  width: 50%;
  float: left;
}

.hidden {
  display: none;
}

/* The switch - the box around the slider */
.switch {
  z-index: 2;
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 24px;
  width: 24px;
  left: 6px;
  bottom: 5px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slided {
  -webkit-transition: 0s;
  transition: 0s;
}

.slided:before {
  -webkit-transition: 0s;
  transition: 0s;
}

input:checked + .slider {
  background-color: $color-1;
}

input:focus + .slider {
  box-shadow: 0 0 1px $color-1;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 1px;
}

.slider.round:before {
  border-radius: 1px;
}

/*******************/

.magnifier {
  height: 100%;
  margin-left: auto;
  position: relative;
  font-size: 15px;
  padding-right: 12px;
  padding-left: 12px;
  background: transparent;
  border: none;
}

canvas {
  cursor: none;
}

.search-field {
  width: 70%;
  box-sizing: border-box;
}

.search-container {
  margin: 4px;
}

.pdf-download-link a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 320px;
  height: 32px;
  border: 1px solid black;
  color: black;
  margin: 15px auto 30px auto;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.4);
}

.part-data {
  margin: 8px 3px;
}

.three-dots {
  height: 27px;
  transform: rotate(90deg);
  fill: $color-1b;
}

.fa-search,
.fa-arrow-left {
  color: $color-1b;
}
